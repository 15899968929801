import _ from 'lodash';
import extendReactClass from './extendReactClass';
import wrapStatelessFunction from './wrapStatelessFunction';
import makeConfiguration from './makeConfiguration';
/**
 * @see https://github.com/gajus/react-css-modules#options
 */
/**
 * Determines if the given object has the signature of a class that inherits React.Component.
 */
var isReactComponent = function (maybeReactComponent) {
    return ('prototype' in maybeReactComponent &&
        _.isFunction(maybeReactComponent.prototype.render));
};
/**
 * When used as a function.
 */
var functionConstructor = function (Component, defaultStyles, options) {
    var decoratedClass;
    var configuration = makeConfiguration(options);
    if (isReactComponent(Component)) {
        decoratedClass = extendReactClass(Component, defaultStyles, configuration);
    }
    else {
        decoratedClass = wrapStatelessFunction(Component, defaultStyles, configuration);
    }
    if (Component.displayName) {
        decoratedClass.displayName = Component.displayName;
    }
    else {
        decoratedClass.displayName = Component.name;
    }
    return decoratedClass;
};
/**
 * When used as a ES7 decorator.
 */
var decoratorConstructor = function (defaultStyles, options) {
    return function (Component) {
        return functionConstructor(Component, defaultStyles, options);
    };
};
export default (function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (_.isFunction(args[0])) {
        return functionConstructor(args[0], args[1], args[2]);
    }
    else {
        return decoratorConstructor(args[0], args[1]);
    }
});
