/* eslint-disable react/prop-types */
import { __read, __spread } from "tslib";
import React from 'react';
import _ from 'lodash';
import linkClass from './linkClass';
import renderNothing from './renderNothing';
/**
 * @see https://facebook.github.io/react/blog/2015/09/10/react-v0.14-rc1.html#stateless-function-components
 */
export default (function (Component, defaultStyles, options) {
    var WrappedComponent = function (props) {
        if (props === void 0) { props = {}; }
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var styles;
        var useProps;
        var hasDefaultstyles = _.isObject(defaultStyles);
        if (props.styles || hasDefaultstyles) {
            useProps = Object.assign({}, props);
            if (props.styles) {
                styles = props.styles;
            }
            else {
                styles = defaultStyles;
            }
            Object.defineProperty(useProps, 'styles', {
                configurable: true,
                enumerable: false,
                value: styles,
                writable: false,
            });
        }
        else {
            useProps = props;
            styles = {};
        }
        var renderResult = Component.apply(void 0, __spread([useProps], args));
        if (renderResult) {
            return linkClass(renderResult, styles, options);
        }
        return renderNothing(React.version);
    };
    _.assign(WrappedComponent, Component);
    return WrappedComponent;
});
