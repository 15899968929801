var default_1 = /** @class */ (function () {
    function default_1() {
        this.size = 0;
        this.keys = [];
        this.values = [];
    }
    default_1.prototype.get = function (key) {
        var index = this.keys.indexOf(key);
        return this.values[index];
    };
    default_1.prototype.set = function (key, value) {
        this.keys.push(key);
        this.values.push(value);
        this.size = this.keys.length;
        return value;
    };
    return default_1;
}());
export default default_1;
