import _ from 'lodash';
var styleNameIndex = {};
export default (function (styleNamePropertyValue, allowMultiple) {
    var styleNames;
    if (styleNameIndex[styleNamePropertyValue]) {
        styleNames = styleNameIndex[styleNamePropertyValue];
    }
    else {
        styleNames = _.trim(styleNamePropertyValue).split(/\s+/);
        styleNames = _.filter(styleNames);
        styleNameIndex[styleNamePropertyValue] = styleNames;
    }
    if (allowMultiple === false && styleNames.length > 1) {
        throw new Error('ReactElement styleName property defines multiple module names ("' +
            styleNamePropertyValue +
            '").');
    }
    return styleNames;
});
