import SimpleMap from './SimpleMap';
var CustomMap = typeof Map === 'undefined' ? SimpleMap : Map;
var stylesIndex = new CustomMap();
export default (function (styles, styleNames, handleNotFoundStyleName) {
    var appendClassName;
    var stylesIndexMap;
    stylesIndexMap = stylesIndex.get(styles);
    if (stylesIndexMap) {
        var styleNameIndex = stylesIndexMap.get(styleNames);
        if (styleNameIndex) {
            return styleNameIndex;
        }
    }
    else {
        stylesIndexMap = new CustomMap();
        stylesIndex.set(styles, new CustomMap());
    }
    appendClassName = '';
    for (var styleName in styleNames) {
        if (styleNames.hasOwnProperty(styleName)) {
            var className = styles[styleNames[styleName]];
            if (className) {
                appendClassName += ' ' + className;
            }
            else {
                if (handleNotFoundStyleName === 'throw') {
                    throw new Error('"' + styleNames[styleName] + '" CSS module is undefined.');
                }
                if (handleNotFoundStyleName === 'log') {
                    // eslint-disable-next-line no-console
                    console.warn('"' + styleNames[styleName] + '" CSS module is undefined.');
                }
            }
        }
    }
    appendClassName = appendClassName.trim();
    stylesIndexMap.set(styleNames, appendClassName);
    return appendClassName;
});
