import _ from 'lodash';
/**
 * @typedef CSSModules~Options
 * @see {@link https://github.com/gajus/react-css-modules#options}
 * @property {boolean} allowMultiple
 * @property {string} handleNotFoundStyleName
 */
/**
 * @param {CSSModules~Options} userConfiguration
 * @returns {CSSModules~Options}
 */
export default (function (userConfiguration) {
    if (userConfiguration === void 0) { userConfiguration = {}; }
    var configuration = {
        allowMultiple: false,
        handleNotFoundStyleName: 'throw',
    };
    _.forEach(userConfiguration, function (value, name) {
        if (_.isUndefined(configuration[name])) {
            throw new Error('Unknown configuration property "' + name + '".');
        }
        if (name === 'allowMultiple' && !_.isBoolean(value)) {
            throw new Error('"allowMultiple" property value must be a boolean.');
        }
        if (name === 'handleNotFoundStyleName' &&
            !_.includes(['throw', 'log', 'ignore'], value)) {
            throw new Error('"handleNotFoundStyleName" property value must be "throw", "log" or "ignore".');
        }
        configuration[name] = value;
    });
    return configuration;
});
