/* eslint-disable react/prop-types */
import { __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import hoistNonReactStatics from 'hoist-non-react-statics';
import linkClass from './linkClass';
import renderNothing from './renderNothing';
/**
 * @param {ReactClass} Component
 * @param {Object} defaultStyles
 * @param {Object} options
 * @returns {ReactClass}
 */
export default (function (Component, defaultStyles, options) {
    var WrappedComponent = /** @class */ (function (_super) {
        __extends(WrappedComponent, _super);
        function WrappedComponent() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        WrappedComponent.prototype.render = function () {
            var styles;
            var hasDefaultstyles = _.isObject(defaultStyles);
            var renderResult;
            if (this.props.styles || hasDefaultstyles) {
                var props = Object.assign({}, this.props);
                if (props.styles) {
                    styles = props.styles;
                }
                else if (hasDefaultstyles) {
                    styles = defaultStyles;
                    delete props.styles;
                }
                Object.defineProperty(props, 'styles', {
                    configurable: true,
                    enumerable: false,
                    value: styles,
                    writable: false,
                });
                var originalProps = this.props;
                var renderIsSuccessful = false;
                try {
                    this.props = props;
                    renderResult = _super.prototype.render.call(this);
                    renderIsSuccessful = true;
                }
                finally {
                    this.props = originalProps;
                }
                // @see https://github.com/facebook/react/issues/14224
                if (!renderIsSuccessful) {
                    renderResult = _super.prototype.render.call(this);
                }
            }
            else {
                styles = {};
                renderResult = _super.prototype.render.call(this);
            }
            if (renderResult) {
                return linkClass(renderResult, styles, options);
            }
            return renderNothing(React.version);
        };
        return WrappedComponent;
    }(Component));
    return hoistNonReactStatics(WrappedComponent, Component);
});
